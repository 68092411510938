import React, {useState, useEffect, useRef} from 'react';
import { Outlet, Link, NavLink } from 'react-router-dom';
import '../styles/layout.css'
import Qol from '../assets/qol_logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Footer from './Footer';







function Layout() {
   const [menuOpen, setMenuOpen] = useState(false);
   const navbarRef = useRef(null);

   const handleNavbar = () => setMenuOpen(!menuOpen);
   
   
    

    useEffect(() => {
      const handleOutsideClick = (event) => {
        if (menuOpen && !navbarRef.current.contains(event.target)) {
          setMenuOpen(false);
        }
      };
      document.addEventListener('click', handleOutsideClick);
      return () => {
        document.removeEventListener('click', handleOutsideClick)
      };
    }, [menuOpen, navbarRef]);
   

   

  return (
    <>
      <nav className='navbar px-2 py-4 md:py-6 lg:py-6 flex flex-row justify-center  w-full' id='navbar' ref={navbarRef}>
       <div className='container navbar-content flex'>
       <div className='brand-and-toggler flex flex-sb'>
       <Link to='/' className='navbar-brand flex'>
          <img src={Qol} alt="logo" id='logo'/>
          
         </Link>
         <button type='button' aria-label="Menu" className='navbar-toggler-btn' onClick={handleNavbar}> <FontAwesomeIcon icon={faBars} id='faBars' style={{color: `${menuOpen ? "#fff" : "#125dF4"}`}}/></button>
       </div>
       <div className={menuOpen ? "navbar-collapse show-navbar-collapse" : "navbar-collapse"}>
       <ul className='navbar-nav'>
          
          
          
          <li className='nav-item'>
            <NavLink to="https://medium.com/@qollabs.ai">Blog</NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to="https://t.me/qollabs" className=''>Community</NavLink>
          </li>
         

          <li className='nav-item'>
          <NavLink to="/contact">Contact</NavLink>
          </li>
           
          <li className='nav-item'>
          <NavLink to="https://qollabs.xyz/waitlist/">Waitlist</NavLink>
          </li>

        </ul>
       </div>
       
       </div>
        
      </nav>

      <Outlet />
      <Footer/>
    </>
  )
}

export default Layout