import React from "react";
import Halo from "../assets/halo.png";
import "../styles/carousel.css"

function CarouselItemOne() {
  return (
    <div className="bg-black block md:flex lg:flex md:flex-row lg:flex-row justify-center mx-auto">
      <div className="w-full md:w-3/6 lg:3/6 h-3/6 md:h-full lg:h-full flex flex-row mx-auto justify-center my-3">
       <div className="flex flex-row justify-center mx-auto">
       <img src={Halo} alt="phone mockup" id="halo" className="mx-auto object-fit: contain object-center" />
        </div> 
       
      </div>
      <div className="w-full md:w-3/6 lg:w-3/6 block md:px-12 lg:px-12">
        <p className="text-white grade font-black md:text-5xl lg:text-5xl text-3xl md:py-10 lg:py-10 py-2 text-center">The Qol Super App</p>
        <p className="text-pretty text-white md:text-2xl lg:text-2xl text-base md:py-10 lg:py-10 py-2 text-center">
          Start your journey<br/> into a world of <br/>decentralization ecosystems today<br/>
          with Qol Super App.
        </p>
        <div className="hero-button flex flex-row justify-center mt-2 mx-auto">
          <a href="https://qollabs.xyz/waitlist/">
            <button className="list button">Join Wait-list</button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default CarouselItemOne;
