import React, { useState } from "react";
import "../styles/blog.css";

// import Ai from "../assets/ai.png";
// import Gpt from "../assets/gpt.png";
// import Btc from "../assets/Btc.png";

import "../styles/singleblog.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";


const blogData = [
  {
    id: 1,
    title: "Welcome To Qol Labs",
    author: "Adedamola Adegoke",
    date: "May 8, 2024",
    // image: Ai,
    content:
      "Qol Labs is a pioneering research and development firm dedicated to democratizing Web3 technologies for all. We are centered on leading...",
  },
  {
    id: 2,
    title: "What Is PIM",
    author: "Adedamola Adegoke",
    date: "June 12, 2024",
    // image: Gpt,
    content:
      "PIM is an innovative blockchain protocol aimed at returning internet control to users, disrupting the dominance of large corporations in data management. A Protocol for Information and Value Exchange...",
  },
  {
    id: 3,
    title: "The Qol Super App",
    author: "Adedamola Adegoke",
    date: "June 14, 2024",
    // image: Btc,
    content:
      "The Qol Super App, crafted by Qol Labs, serves as an extensive web3 project platform. It integrates airdrops, launchpad services, and crowdfunding features to offer a robust experience. This innovative....",
  },
  {
    id: 4,
    title: "QolDB",
    author: "Adedamola Adegoke",
    date: "June 21, 2024",
    // image: server,
    content:
      "QolDB incentivizes users by rewarding them for sharing their data. QolDB is an innovative data marketplace enabling users to monetize their data and unused bandwidth in exchange for rewards in the form of Qol Points, which can later be converted into Qol Tokens. By consenting....",
  },
];

function Blog() {
  const [showNoResults, setShowNoResults] = useState(false);
  // const [searchQuery, setSearchQuery] = useState("");
  const [filteredBlogData, setFilteredBlogData] = useState([]);

  function searchArticle(event) {
    event.preventDefault();
    const query = event.target.value.toLowerCase();
    // setSearchQuery(query);
    if (query === "") {
      setFilteredBlogData([]);
      setShowNoResults(false);
     
    } else {
      const filteredData = blogData.filter((blog) => {
        const title = blog.title.toLowerCase();
        const content = blog.content.toLowerCase();
        return title.includes(query) || content.includes(query);
      });
      setFilteredBlogData(filteredData);
      setShowNoResults(filteredData.length === 0);
    }
  }

  return (
    <div className="blog mt-28">
      <h1 className="flex flex-row justify-center mx-auto text-7xl mt-12">
        Blogs
      </h1>
      <div className="searchbar-container flex flex-row  mx-auto my-16 w-3/4 md:w-3/4 lg:w-3/4">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="icon mr-3 text-slate-500 ml-3"
        />
        <input
          className="searchbar w-full"
          type="text"
          placeholder="Search"
          onChange={searchArticle}
          name="search"
          id="searchbar"
        />
      </div>
     {showNoResults && (
      <div className="no-results flex flex-row mx-auto justify-center">
        <p className="text-white text-center">No results found</p>
      </div>
     )}

      {filteredBlogData.map((blog) => (
        <div
          key={blog.id}
          className="blog-card md:w-2/5 lg:w-2/5 w-3/4 mx-auto my-4"
        >
          <img src={blog.image} alt="visuals" />
          <div className="flex flex-row justify-between p-4">
            <span className="text-start text-base text-slate-500">
              {blog.author}
            </span>
            <span className="text-end text-base text-slate-500">
              {blog.date}
            </span>
          </div>
          <div className="p-4 leading-6">
            <p className="text-start font-bold mb-5">{blog.title}</p>
            <p className="text-start text-pretty text-base font-normal">
              {blog.content}
            </p>
          </div>
          <div className="p-4 text-start text-white text-xs">
            <a
              href="https://medium.com/@qollabs.ai"
              className="text-slate-400 hover:text-blue-600 "
            >
              <FontAwesomeIcon icon={faArrowRight} className="mx-1" />
              Read More
            </a>
          </div>
        </div>
      ))}

      <h2 className="flex flex-row justify-center mx-auto md:text-4xl lg:text-4xl text-2xl my-12">
        Building A Decentralized Future
      </h2>
      <div className="container block md:flex lg:flex md:flex-row lg:flex-row justify-center mx-auto">
        <div className="blog-card md:w-2/5 lg:w-2/5 w-3/4 mx-auto my-8">
          {/* <img src={Ai} alt="visualization" /> */}
          <div className="flex flex-row justify-between p-4">
            <span className="text-start text-base text-slate-500">
              
            </span>
            <span className="text-end text-base text-slate-500">
              May 8, 2024
            </span>
          </div>
          <div className="p-4 leading-6">
            <p className="text-start font-bold mb-5">Welcome To Qol Labs</p>
            <p className="text-start text-pretty  text-base font-normal">
              Qol Labs is a pioneering research and development firm dedicated
              to democratizing Web3 technologies for all. We are centered on
              leading....
            </p>
          </div>
          <div className="p-4 text-start text-white text-xs">
            <a
              href="https://medium.com/@qollabs.ai"
              className="text-slate-400 hover:text-blue-600"
            >
              <FontAwesomeIcon icon={faArrowRight} /> Read More
            </a>
          </div>
        </div>
        <div className="blog-card md:w-2/5 lg:w-2/5 w-3/4 mx-auto my-8">
          {/* <img src={Gpt} alt="visualization" /> */}
          <div className="flex flex-row justify-between p-4">
            <span className="text-start text-base text-slate-500">

            </span>
            <span className="text-end text-base text-slate-500">
              June 12, 2024
            </span>
          </div>
          <div className="p-4 leading-6">
            <p className="text-start font-bold mb-5">What Is PIM?</p>
            <p className="text-start text-pretty  text-base font-normal">
              PIM is an innovative blockchain protocol aimed at returning
              internet control to users, disrupting the dominance of large
              corporations in data management. A Protocol for Information and
              Value Exchange...
            </p>
          </div>
          <div className="p-4 text-start text-white text-xs">
            <a
              href="https://medium.com/@qollabs.ai"
              className="text-slate-400 hover:text-blue-600"
            >
              <FontAwesomeIcon icon={faArrowRight} /> Read More
            </a>
          </div>
        </div>
      </div>

      <div className="container block md:flex lg:flex md:flex-row lg:flex-row justify-center mx-auto">
        <div className="blog-card md:w-2/5 lg:w-2/5 w-3/4 mx-auto my-8">
          {/* <img src={Btc} alt="visualization" /> */}
          <div className="flex flex-row justify-between p-4">
            <span className="text-start text-base text-slate-500">
    
            </span>
            <span className="text-end text-base text-slate-500">
              June 14, 2024
            </span>
          </div>
          <div className="p-4 leading-6">
            <p className="text-start font-bold mb-5">The Qol Super App</p>
            <p className="text-start text-pretty  text-base font-normal">
              The Qol Super App, crafted by Qol Labs, serves as an extensive
              web3 project platform. It integrates airdrops, launchpad services,
              and crowdfunding features to offer a robust experience. This
              innovative....
            </p>
          </div>
          <div className="p-4 text-start text-white text-xs">
            <a
              href="https://medium.com/@qollabs.ai"
              className="text-slate-400 hover:text-blue-600"
            >
              <FontAwesomeIcon icon={faArrowRight} /> Read More
            </a>
          </div>
        </div>
        <div className="blog-card md:w-2/5 lg:w-2/5 w-3/4 mx-auto my-8">
          {/* <img src={server} alt="visualization" /> */}
          <div className="flex flex-row justify-between p-4">
            <span className="text-start text-base text-slate-500">
    
            </span>
            <span className="text-end text-base text-slate-500">
              June 21, 2024
            </span>
          </div>
          <div className="p-4 leading-6">
            <p className="text-start font-bold mb-5">QolDB</p>
            <p className="text-start text-pretty  text-base font-normal">
              QolDB incentivizes users by rewarding them for sharing their data.
              QolDB is an innovative data marketplace enabling users to monetize
              their data and unused bandwidth in exchange for rewards in the
              form of Qol Points, which can later be converted into Qol Tokens.
              By consenting....
            </p>
          </div>
          <div className="p-4 text-start text-white text-xs">
            <a
              href="https://medium.com/@qollabs.ai"
              className="text-slate-400 hover:text-blue-600"
            >
              <FontAwesomeIcon icon={faArrowRight} /> Read More
            </a>
          </div>
        </div>
      </div>

      <div className="ender w-3/4  h-full md:w-2/4 lg:w-2/4 flex flex-row mx-auto my-8 justify-center rounded-xl">
        <div className="block my-12 ">
          <div className="flex justify-center align-center">
          <h3 className="text-center my-8 text-2xl p-3 ">Join QolLabs Today!</h3>
          </div>
        
         <div className="flex flex-row justify-center align-center">
             <button className="list button">Join Our Community</button>
         </div>
          
        </div>
         
      </div>
    </div>
  );
}

export default Blog;
