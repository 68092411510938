import React, {useState} from 'react';
import '../styles/contact.css';
import chat from '../assets/contact_svg.svg'
import send from '../assets/letter_send.png'

function Contact() {

  const [selectedOption, setSelectedOption] = useState('');

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div>
      <div className='contact mt-28'>
        <h3 className='text-white flex flex-row justify-center mx-auto text-4xl mt-18 mb-2'>Contact Us</h3>
      </div>
      <div>
        <h4 className='text-slate-600 flex flex-row justify-center mx-auto my-6 text-base'>Any questions or remarks? Just write us a message</h4>
      </div>
    <form action="https://formsubmit.co/info@qollabs.org" method="POST">
    <input type="hidden" name="_next" value="https://qollabs.org" />
    <input type="hidden" name="_captcha" value="false" />
    <div className='form_space block md:flex md:flex-row lg:flex lg:flex-row  justify-center mx-auto bg-white w-4/5 rounded-xl my-2 p-5'>
      <div className='live_chat block my-1   md:w-3/6 lg:w-3/6 md:my-1 lg:my-1  rounded-xl '>
        <img src={chat} alt='chat with us'/>
      </div>
      <div className='form_field_container block mx-4'>
        <div className='form_field grid grid-cols-2 gap-2 place-items-center mt-6'>
        <div>
        <label htmlFor='firstname'className='text-black text-base lg:text-left md:text-left font-medium'>First Name</label>
       <input name='first_name' className='block w-full text-black' placeholder='John' required/>
        </div>
        <div>
        <label htmlFor='lastname'className='text-black text-base lg:text-left md:text-left font-medium'>Last Name</label>
       <input name='last_name' className='block w-full text-black' placeholder='Doe' required/>
        </div>
        <div>
        <label htmlFor='email'className='text-black text-base md:text-left lg:text-left font-medium'>Email</label>
       <input name='email' className='block w-full  text-black' type='email' placeholder='johndoe@gmail.com' required/>
        </div>
        <div>
<label htmlFor='region' className='text-black text-base font-medium'>Subject</label>
                <select name='region' className='block w-full text-black text-sm border-b-stone-600' value={selectedOption} onChange={handleOptionChange}>
                  <option value=''></option>
                  <option value='General Inquiry' className='text-sm'>General Inquiry</option>
                  <option value='Collaboration' className='text-sm'>Collaboration</option>
                  <option value='Other' className='text-sm'>Other</option>
                </select>
       
        </div>
      </div>

     

     <div className='message_area block mx-2 my-6'>
      <label className='text-slate-500 text-base fw-5' htmlFor='message'>Message</label>
      <input placeholder='Write your message' className='border-b-slate-500 block my-2 w-4/5' name='message'/>

      <div className='flex flex-row justify-end mr-8 mt-7'>
      <button className=' rounded-lg flex flex-row justify-end text-base' id="send_button">Send Message</button>
      </div>
      <div className='flex flex-row justify-end mr-16'>
        <img src={send} alt='send message' id='letter'/>
      </div>
      
     </div>
      </div>
      
      
     </div>
    </form>

     <form>
     
     </form>

    
    </div>
  )
}

export default Contact