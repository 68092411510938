
import CarouselItemOne from './CarouselItemOne';
import CarouselItemTwo from './CarouselItemTwo';
import CarouselItemThree from './CarouselItemThree';
import CarouselItemFour from './CarouselItemFour';

const rewards = [
  {
    content: <CarouselItemOne />,
  },
  {
    content: <CarouselItemTwo />,
  },
  {
    content: <CarouselItemThree />,
  },
  {
    content: <CarouselItemFour/>,
  }
];

export { rewards };