import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './pages/Layout';
import Home from './pages/Home';
// import About from './pages/About';
import Blog from './pages/Blog';
import Contact from './pages/Contact';
import Waitlist from './pages/Waitlist';
import "./index.css"







const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
  <Routes>
    <Route path='/' element= {<Layout/>}>
      <Route index element= {<Home/>} />
      {/* <Route path='about' element= {<About/>} /> */}
      <Route path='blog' element= {<Blog/>} />
      <Route path='contact' element= {<Contact/>} />
      <Route path='waitlist' element= {<Waitlist/>} /> 
     
    </Route>
  </Routes>
  </BrowserRouter>
);


