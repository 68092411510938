import React, { useEffect, useState, useRef, useCallback } from 'react';
import "../styles/carousel.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import {rewards} from '../components/Data'


function Carousel() {
  console.log(rewards); 
  const [current, setCurrent] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const timeOutRef = useRef(null);
 
  const slideRight = useCallback(() => {
    setCurrent(current === rewards.length - 1 ? 0 : current + 1);
  }, [current, setCurrent]);
  
  
  const slideLeft = useCallback(() => {
    setCurrent(current === 0 ? rewards.length - 1 : current - 1);
  }, [current,  setCurrent]);
  
  useEffect(() => {
    if (autoPlay) {
      timeOutRef.current = setTimeout(() => {
        slideRight();
      }, 2000);
    }
    return () => {
      clearTimeout(timeOutRef.current);
    };
  }, [autoPlay, slideRight]);

  // const slideRight = () => {
  //   setCurrent(current === images.length - 1 ? 0 : current + 1);
  // };
  // const slideLeft = () => {
  //   setCurrent(current === 0 ? images.length - 1 : current - 1);
  // };



  return (
    <div className="section block">
          <div className='carousel w-4/5 md:w-5/5 lg:w-5/5' onMouseEnter={() =>{setAutoPlay(false); clearTimeout(timeOutRef.current)}} onMouseLeave={() => {setAutoPlay(true)}}>
      <div className="carousel-wrapper">
      {rewards.map((reward, index) => {
          return <div key={index} className={index === current ? "carousel_card carousel_card_active" : "carousel_card"}>
            {reward.content}
          </div>
       })}

    

      </div>
       
      
         </div>
         <div className='carousel_controls flex flex-row justify-center md:mt-8 lg:mt-8'>
         <div className='carousel_arrow-left mr-3  text-transparent text-sm' onClick={slideLeft}><FontAwesomeIcon icon={faCircle} /></div>
         <div className='carousel_arrow-left mr-3  text-transparent text-sm' onClick={slideRight}><FontAwesomeIcon icon={faCircle} /></div>
        
         </div>
         <div className='carousel_pagination flex flex-row justify-center mt-1'>
             {rewards.map((_, index) => {
              return <div key={index} className={index === current ? "pagination_dot pagination_dot_active" : "pagination_dot"} onClick={() => setCurrent(index)}>
                  <div className='carousel_arrow-left mr-3 text-sm' ><FontAwesomeIcon icon={faCircle} /></div>
        
              </div>
             })}
         </div>



   
    </div>

  )
}

export default Carousel
